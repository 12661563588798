@use '@angular/material' as mat;
@import "@ng-select/ng-select/themes/default.theme.css";
// @import "~@fullcalendar/core/main.css";
// @import "~@fullcalendar/daygrid/main.css";
@import "./assets/styles/vendor/fullcalendar-themed";
@import "./assets/styles/core-theme";
@import "./assets/styles/utilities";
@import "./assets/styles/mixins/mixin.module";

@import 'ngx-progressive-image-loader/ngx-progressive-image-loader';
@import "./assets/styles/vendor/ngx-progressive-image-loader-themed";

$scale: 0.5; // the scale of loading image in place holder
@include progressive-image-loader($scale);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($bddc-typography);`
@include mat.all-legacy-component-typographies($bddc-typography);
@include mat.legacy-core();

//@include angular-material-typography($bddc-typography);
@include mat.all-legacy-component-themes($bddc-theme);

html,
body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.content-wrapper {
  width: 95%;
  max-width: 1194px;
  margin: 0 auto;

  @include breakpoint("phone-average") {
    padding-left: 0;
    width: 90%;
  }
}

.app-loader {
  width: 100%;
  height: 100%;
  background-color: rgba($white, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

// Placed invoice card styling here to take precedence when loading mat-list
.invoice-card {
  .mat-list-base {
    .mat-list-item {
      .mat-list-item-content {
        padding: 0;
        justify-content: space-between;
      }
    }
  }
}

.sleep-over-info-container {
  .mat-form-field-infix {
    padding-left: 0px;
  }
}

hr.hr-styling {
  width: 100%;
  color: $soft-blue-2;
  border-top: 0px;

  @include breakpoint("phone-iphone5") {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @include breakpoint("tablet") {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  &.flat-hr {
    margin: 0 0 0 24px;
    width: calc(100% - 48px);

    @include breakpoint("tablet") {
      margin: 0 0 0 24px;
    }
  }

  &.no-margin {
    margin: 0;
  }
}

.bddc {
  ol {
    margin: 0;
    padding: 0 14px;
    color: $gray-blue;
  }
}

.primary-blue-bg {
  background-color: $primary-blue-bg;
}

.bddc {
  .cdk-overlay-container {
    &.error-panel {
      display: flex;
      align-items: center;
      justify-content: center;

      .cdk-overlay-backdrop-showing {
        background-color: rgba($primary-blue, 0.85);
      }

      div[dir="ltr"] {
        width: 288px;
        height: 383px;
        position: relative;

        @include breakpoint("phone-iphone6") {
          width: 343px;
        }

        @include breakpoint("laptop") {
          width: 377px;
          height: 384px;
        }
      }

      .cdk-overlay-pane {
        background-color: $white;
        border-radius: 8px;

        @include breakpoint("laptop") {
          border-radius: 16px;
        }
      }
    }
  }
}

.bddc {
  main {
    min-height: calc(100vh - 270px);
  }

  // override the image cropper image container width:
  .image-wrapper {
    image-cropper>div img {
      @include breakpoint("tablet") {
        max-width: 276px !important;
        max-height: 185px !important;
      }
    }
  }

  image-cropper>div:first-child {
    touch-action: auto !important;
  }
}

.account-balance-card {
  .mat-icon {
    svg {
      .a {
        fill: $blue-gray !important;
      }
    }
  }
}

.service-dialog-container .mat-dialog-container {
  overflow-y: hidden;
}